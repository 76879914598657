<template>
    <v-main>
        <BackOnTop
            v-if="!isRating"
            title="消息中心"
            :labelRight="`${currentTab == 'msg' ? '全部已讀' : ''}`"
            showRight
            url="/"
            @click:right="readInfo(-1)"
        />
        <v-container
            v-if="!$store.state.isLoading"
            fluid
            style="padding-left: 32px; padding-right: 32px; padding-top: 16px"
        >
            <div class="info_wrappper">
                <div class="col-12" style="position: sticky; top: 50px">
                    <div class="col-12 row">
                        <div
                            :class="`col-6 ${
                                currentTab == 'msg'
                                    ? 'style-active-tab'
                                    : 'style-inactive-tab'
                            }`"
                            style="background-color: white"
                            @click="currentTab = 'msg'"
                        >
                            個人消息
                        </div>
                        <div
                            :class="`col-6 ${
                                currentTab == 'news'
                                    ? 'style-active-tab'
                                    : 'style-inactive-tab'
                            }`"
                            style="background-color: white"
                            @click="currentTab = 'news'"
                        >
                            最新消息
                        </div>
                    </div>
                </div>
                <ul
                    class="msg_list"
                    v-if="currentTab === 'msg'"
                    style="overflow: scroll; height: 90vh"
                >
                    <li
                        class="d_flex col-12"
                        v-for="(el, idx) in msgList"
                        :key="idx"
                        @click="
                            readInfo(el.id);
                            el.is_read = true;
                            redirectHandler(el.type, el.pageidorurl);
                        "
                    >
                        <div class="detail f_black col-12">
                            <div
                                class="row"
                                style="align-items: center; margin-bottom: 6px"
                            >
                                <div
                                    v-if="!el.is_read"
                                    style="
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 100%;
                                        background-color: red;
                                        margin-bottom: 10px;
                                        margin-right: 8px;
                                    "
                                ></div>
                                <p
                                    class="style-body-regular"
                                    style="
                                        font-weight: 700;
                                        color: #0d0c0c;
                                        letter-spacing: 0.56px;
                                    "
                                >
                                    {{ el.title }}
                                </p>
                            </div>
                            <p
                                class="style-body-regular"
                                style="
                                    color: #6f6f6f;
                                    min-height: 30px;
                                    margin-bottom: 16px;
                                "
                            >
                                {{ el.detail }}
                            </p>
                            <div
                                class="row col-12"
                                style="
                                    justify-content: flex-end;
                                    position: relative;
                                    bottom: 0px;
                                "
                            >
                                <div
                                    class="col-6 style-body-regular row"
                                    style="
                                        font-size: 12px;
                                        display: flex;
                                        align-items: center;
                                    "
                                >
                                    <div
                                        :style="`margin-right: 6px; height:18px;width: 18px;background-image: url(${
                                            iconMap[el.type]
                                                ? iconMap[el.type]
                                                : iconMap['system']
                                        });`"
                                    ></div>
                                    <div style="color: #363230">
                                        {{
                                            labelMap[el.type]
                                                ? labelMap[el.type]
                                                : labelMap['system']
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-6"
                                    style="
                                        margin-top: 3px;
                                        text-align: right;
                                        color: #363230;
                                        font-size: 12px;
                                    "
                                >
                                    {{ el.time.split(' ')[0] }}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <!-- <ul class="news_list" v-else  style="overflow: scroll;height: 90vh;">
                    <li class="d_flex" v-for="(el, idx) in newsList" :key="idx">
                        <router-link
                            :to="{
                                path: '/info/news',
                                query: {
                                    category_id: el.category_id,
                                    page: el.page,
                                    type: '最新消息',
                                },
                            }"
                        >
                            <div class="img_wrapper">
                                <img :src="el.imgSrc" alt="" />
                            </div>
                            <div class="detail">
                                <h4>{{ el.title }}</h4>
                                <div>
                                    <font>{{ el.type }}</font
                                    ><span>{{ el.time }}</span>
                                </div>
                                <p>{{ el.detail }}</p>
                            </div>
                        </router-link>
                    </li>
                </ul> -->
                <ul class="news_list" v-else>
                    <li class="d_flex" v-for="(el, idx) in newsList" :key="idx">
                        <router-link
                            :to="{
                                path: '/info/news_for_msg',
                                query: {
                                    category_id: el.category_id,
                                    page: el.page,
                                    type: '最新消息',
                                },
                            }"
                        >
                            <div class="img_wrapper">
                                <img :src="el.imgSrc" alt="" />
                            </div>
                            <div class="detail">
                                <p
                                    class="style-body-regular"
                                    style="
                                        font-weight: 700;
                                        color: #0d0c0c;
                                        margin-bottom: 6px;
                                    "
                                >
                                    {{ el.title }}
                                </p>
                                <p
                                    class="style-body-regular"
                                    style="color: #6f6f6f; margin-bottom: 16px"
                                >
                                    {{ el.detail }}
                                </p>
                                <div
                                    class="col-12 row"
                                    style="justify-content: flex-end"
                                >
                                    <div class="col-6">
                                        <div
                                            style="
                                                padding: 4px 4px;
                                                font-size: 12px;
                                                background-color: #19b86a;
                                                color: white;
                                                border-radius: 4px;
                                                width: fit-content;
                                            "
                                        >
                                            {{ el.type }}
                                        </div>
                                    </div>
                                    <div
                                        class="col-6"
                                        style="
                                            margin-top: 3px;
                                            text-align: right;
                                            color: #0d0c0c;
                                            font-size: 12px;
                                        "
                                    >
                                        {{ el.time }}
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/NewStyle/BackOnTop.vue';
// import Tab from '@/components/Tab.vue';
import { articleList, GetFcmPushList, updateIsRead } from '@/apis/api.js';
export default {
    name: 'InfoIndex',
    components: { BackOnTop },
    data: () => ({
        isRating: false,
        finishNews: false,
        tabList: [
            {
                name: '個人消息',
                value: 'msg',
            },
            {
                name: '最新消息',
                value: 'news',
            },
        ],
        currentTab: 'msg',
        msgList: [],
        newsList: [],
        labelMap: {
            cosmetic: '美容預約',
            recommend: '職人推薦',
            onlinedm: '線上DM',
            coupon: '優惠票券',
            nutritioncalculator: '營養計算神器',
            url: 'URL',
            system: '系統',
        },
        iconMap: {
            cosmetic: require('@/assets/images/info-system.svg'),
            recommend: require('@/assets/images/info-product.svg'),
            onlinedm: require('@/assets/images/info-dm.svg'),
            coupon: require('@/assets/images/info-coupon.svg'),
            nutritioncalculator: require('@/assets/images/info-calculator.svg'),
            url: require('@/assets/images/info-system.svg'),
            system: require('@/assets/images/info-system.svg'),
        },
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    created() {
        if (
            !localStorage.getItem('phone') ||
            !localStorage.getItem('password') ||
            !localStorage.getItem('member_no')
        ) {
            this.$router.push('/login');
        } else {
            if (this.$route.query.type == 'msg') this.currentTab = 'news';
            this.loadFcmList();
        }
    },
    mounted: function mounted() {
        if (
            !localStorage.getItem('phone') ||
            !localStorage.getItem('password') ||
            !localStorage.getItem('member_no')
        )
            this.$router.push('/login');
        var parent = this;
        articleList().then(function (rsp) {
            if (rsp.length) parent.newsList = [];
            console.log('articleList', rsp);
            for (let i in rsp) {
                if (rsp[i].is_useful == 1) {
                    parent.newsList.push({
                        type:
                            rsp[i].category_id == 'news.lates'
                                ? '活動快訊'
                                : '優惠訊息',
                        time: rsp[i].created_at
                            .split(' ')[0]
                            .replace(/-/g, '/'),
                        title: rsp[i].title,
                        detail: rsp[i].subject,
                        imgSrc: rsp[i].pic_url.replaceAll(
                            'http://',
                            'https://'
                        ),
                        page: parseInt(rsp[i].sort_id) + 1,
                        category_id: rsp[i].category_id,
                    });
                }
                if (i == rsp.length - 1) {
                    parent.finishNews = true;
                    parent.$store.state.isLoading = false;
                }
            }
            if (rsp.length == 0) {
                parent.finishNews = true;
                parent.$store.state.isLoading = false;
            }
        });
    },
    methods: {
        tabHandler(value) {
            this.currentTab = value;
        },
        loadFcmList() {
            var numberOfMlSeconds = new Date().getTime();
            var num = 0;
            var newDateObj = new Date(
                numberOfMlSeconds - 1000 * 60 * 60 * 24 * num
            );
            // console.log(this.selectedTime ,numberOfMlSeconds,newDateObj)
            var now_date =
                newDateObj.getFullYear() +
                (newDateObj.getMonth() + 1 < 10
                    ? '0' + (newDateObj.getMonth() + 1)
                    : newDateObj.getMonth() + 1) +
                (newDateObj.getDate() < 10
                    ? '0' + newDateObj.getDate()
                    : newDateObj.getDate());
            num = 7;
            newDateObj = new Date(
                numberOfMlSeconds - 1000 * 60 * 60 * 24 * num
            );
            // newDateObj = new Date(numberOfMlSeconds + 1000*60*60*24*num);
            var end_date =
                newDateObj.getFullYear() +
                (newDateObj.getMonth() + 1 < 10
                    ? '0' + (newDateObj.getMonth() + 1)
                    : newDateObj.getMonth() + 1) +
                (newDateObj.getDate() < 10
                    ? '0' + newDateObj.getDate()
                    : newDateObj.getDate());
            console.log(now_date, end_date);
            var parent = this;
            GetFcmPushList(
                localStorage.getItem('phone'),
                localStorage.getItem('password')
            ).then(function (rsp) {
                // if (typeof rsp != 'object') rsp = JSON.parse(rsp);
                if (rsp) {
                    parent.msgList = [];
                    rsp = rsp.reverse();
                    rsp.sort(function (a, b) {
                        return (
                            b.date.replace(/\//g, '').replace(/:/g, '') -
                            a.date.replace(/\//g, '').replace(/:/g, '')
                        );
                    });
                    for (let i in rsp) {
                        parent.msgList.push({
                            id: rsp[i].id,
                            title: rsp[i].title,
                            time:
                                rsp[i].time.length == 2
                                    ? [
                                          rsp[i].date.slice(0, 4),
                                          rsp[i].date.slice(4, 6),
                                          rsp[i].date.slice(6, 8),
                                      ].join('/') +
                                      ' ' +
                                      rsp[i].time +
                                      ':00'
                                    : [
                                          rsp[i].date.slice(0, 4),
                                          rsp[i].date.slice(4, 6),
                                          rsp[i].date.slice(6, 8),
                                      ].join('/') +
                                      ' ' +
                                      rsp[i].time,
                            detail: rsp[i].content,
                            is_read: rsp[i].is_read === 'True' ? true : false,
                            type: rsp[i].type,
                            pageidorurl: rsp[i].pageidorurl,
                        });
                        // if (
                        //     rsp[i].is_read == 'false' ||
                        //     rsp[i].is_read == 'False'
                        // ) {
                        //     updateIsRead(
                        //         localStorage.getItem('phone'),
                        //         localStorage.getItem('password'),
                        //         rsp[i].id
                        //     ).then(function (rsp) {
                        //         console.log(rsp);
                        //     });
                        // }
                        // if(i == rsp.length - 1){
                        //     parent.msgList.sort(function(a,b){
                        //         return b.time.replace(/\//g,"").replace(/:/g,"") - a.time.replace(/\//g,"").replace(/:/g,"")
                        //     })
                        // }
                    }
                }
            });
        },
        redirectHandler(type, id) {
            let parent = this;
            switch (type) {
                case 'cosmetic':
                    parent.$router.push('/salon/order/edit');
                    break;
                case 'recommend':
                    parent.$router.push('/?item_id=' + id);
                    break;
                case 'onlinedm':
                    parent.$router.push('/dm/');
                    break;
                case 'coupon':
                    parent.$router.push('/info/coupon?tNo=' + id);
                    break;
                case 'nutritioncalculator':
                    parent.$router.push('/tools/calculator');
                    break;
                case 'url':
                    window.open(id);
                    break;
                default:
                    break;
            }
        },
        readInfo(index) {
            const parent = this;
            updateIsRead(
                localStorage.getItem('phone'),
                localStorage.getItem('password'),
                index
            ).then(function (read_rsp) {
                if (index == -1 && read_rsp) {
                    parent.loadFcmList();
                }
            });
        },
    },
    watch: {
        finishNews() {
            this.newsList.sort((a, b) =>
                a.time > b.time ? -1 : b.time > a.time ? 1 : 0
            );
        },
    },
};
</script>
<style lang="scss" scoped>
.info_banner {
    border-radius: 10px;
    height: 80px;
    width: calc(100% - 32px);
    background-color: #f1dacc;
    margin: 16px auto;
    justify-content: space-between;
    align-items: center;

    h6 {
        font-size: 20px;
        padding-left: 20px;
        font-weight: bold;
    }

    img {
        height: 100%;
        width: auto;
    }
}

.msg_list {
    margin-top: 12px;
    padding: 0 0px 70px;
    list-style: none;
    justify-content: center;
    li {
        align-items: center;
        justify-content: space-between;
        padding: 20px 0px;
        width: 296px;
        border-bottom: 1px solid #e6e6e6;
        margin: 0 auto;
        .detail {
            text-align: left;
            min-height: 90px;
        }
    }
}

.news_list {
    padding: 0 0px 70px;
    list-style: none;

    li {
        align-items: center;
        justify-content: space-between;
        padding: 24px 0 0;
        width: 296px;
        border-bottom: 1px solid #e6e6e6;
        margin: 0 auto;
        a {
            width: 100%;
        }

        .img_wrapper {
            border-radius: 12px;
            overflow: hidden;
            display: flex;
            margin-bottom: 12px;
        }

        .detail {
            padding: 8px 0 12px;
            text-align: left;
            width: 100%;

            > * {
                margin-bottom: 6px;
            }

            h4 {
                font-size: 20px;
                line-height: 23px;
                font-weight: bold;
                color: $color_main_first;
            }

            div {
                // background-image: url('~@/assets/images/ic24ArrowR.svg');
                // background-size: 8px;
                // background-repeat: no-repeat;
                // background-position: right center;
                // font-size: 12px;

                font {
                    padding-right: 8px;
                    color: $color_main_second;
                    font-weight: bold;
                }

                span {
                    color: $color_main_grey;
                }
            }
        }

        &:active {
            background: rgba(249, 98, 48, 0.05);
        }
    }
}
</style>
